.layout {
  min-height: 100vh;

}

.content {
  min-height: 60vh;
  padding: 2em;
  background: #FFFFFF;
}
