@import "../../../assets/styles/variables";

.layout {
  min-height: 100vh;
}

.content {
  min-height: 60vh;
  max-width: $max-platform-width;
  background: $background-color;
}

.logoContainer {
  float: left;
  width: auto;
  height: 100%;
  margin: 0 2em 0 0;
  padding-left: 0 !important;
  color: white;
}

.logo {
  height: 1.5rem;
  width: auto;
  margin-right: 0.75rem;
}

@media (min-width: 768px) {
  .layoutHeader {
    display: none;
  }
}
