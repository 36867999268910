@import "../../../assets/styles/variables.scss";

.backButton {
  margin-right: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 20px;
  width: 20px;
  cursor: pointer;

  &:hover {
    svg {
      fill: $primary-color;
    }
  }
}

.steps-overview {
  display: none;
  min-width: max-content;
  padding: 0 1rem 1rem;
}

.show-current-only {
  .ant-steps-item:not(.ant-steps-item-active) {
    display: none;
  }

  .ant-steps-item-active {
    padding-left: 0 !important;
  }

  .ant-steps-item-title::after {
    display: none;
  }

  &.steps-overview {
    display: inline-block;
    padding-bottom: 0;
  }
}

@media (max-width: 767px) {
  .stepper {
    .ant-steps-item:not(.ant-steps-item-active) {
      display: none;
    }

    .ant-steps-item-active {
      padding-left: 0 !important;
    }

    .ant-steps-item-title::after {
      display: none;
    }
  }
  .steps-overview {
    display: inline-block;
  }
}
