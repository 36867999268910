body {
  padding: env(safe-area-inset-top) env(safe-area-inset-right) env(safe-area-inset-bottom) env(safe-area-inset-left);
  background-color: #f0f2f5;
}

pre, code, kbd, samp {
  white-space: pre-wrap;
  font-size: inherit;
  font-family: inherit;
}

.platform-body {
  padding: 1rem;
}

.ant {
  &-layout-header {
    padding: 0;

    .ant-menu-horizontal {
      display: flex;
      justify-content: space-between;
    }
  }

  &-page-header {
    padding: 0.5rem 1rem 1rem;
    background: white;
  }

  &-form {
    &-horizontal {
      .ant-form-item {
        margin-bottom: 1rem;

        &-label {
          padding-bottom: 0;
        }
      }
    }
  }

  &-affix {
    background: white;
  }

  &-card {
    &-actions {
      padding: 0 12px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      > li {
        width: auto !important;

        &:not(:last-child) {
          border-right: unset;
        }
      }

      &:after, &:before {
        display: none;
      }
    }
  }

  &-modal {

    &.modal {
      &-xl {
        width: 780px !important;
        max-width: 96vw;
      }

      &-xxl {
        width: 1040px !important;
        max-width: 96vw;
      }
    }

    &-confirm {
      .ant-modal-confirm-body-wrapper {
        display: flex;
        flex-direction: column;
      }

      .ant-modal-body {
        padding: 1rem;
      }

      .ant-modal-confirm-btns {
        width: 100%;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;

        .ant-btn {
          margin-bottom: 4px;
        }
      }
    }
  }

  &-upload {
    &-list-picture-card .ant-upload-list-item-info::before {
      left: 0;
    }
  }

  &-descriptions {
    &-header {
      margin-bottom: 0.75rem;
    }

    &-title {
      font-weight: normal;
      font-size: 14px;
    }
  }

  &-card-extra {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  &-typography {
    &-edit-content {
      margin: 2px 2px 2px 0.75rem !important;
    }
  }
}

.table-striped-rows tr:nth-child(2n) td {
  background-color: #fbfbfb;
}
.table-striped-rows thead {
  background-color: #f1f1f1;
}

.card-input {
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  padding: 8px;
}

.input-number-suffix {
  height: 32px;
  align-items: center;
  display: flex;
  padding: 0 8px;
  background-color: #fafafa;
  border: 1px solid #d9d9d9;
  border-left: 0;
}

