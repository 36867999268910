.blockContent {
  height: 50vh;
  overflow: auto;
  border: none;
  width: 100%;
}

.blockContent .ant-card-body{
  padding-bottom: 0;
}

.closerSignerBlock {
  margin-top: 1rem;
  position: relative;
  width: max-content;
  float: right;
}

.closerSignerBlockBackLabel {
  font-size: 1em;
  color: rgba(0, 0, 0, .8);
  margin-bottom: 0.25rem;
}

.closerSignerBlockBackLine {
  margin-top: 0.25rem;
  border-bottom: 1px solid rgba(0, 0, 0, .45);
  width: 220px;
}

.closerSignerBlockSignContent {
  left: 0;
  top: 2rem;
  line-height: 1.1;
}
