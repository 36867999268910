$size: 18px;

.settlements {
  .item-stepper {
    margin-top: 8px;
    align-items: center;

    .stepper {
      padding: 0 !important;
      width: auto;

      .ant-steps-item {
        &-icon {
          width: $size;
          height: $size;
          font-size: 12px;
          line-height: $size;
          border-radius: 50%;
          margin: 0 6px 0 0;
        }

        &-title {
          font-size: 12px;
          line-height: $size;
          padding-right: 0!important;

          &::after {
            display: none;
          }
        }

        &:not(.ant-steps-item-active) {
          display: none;
        }

        &-active {
          padding-left: 0 !important;
        }
      }
    }

    .steps-overview {
      display: inline-block;
      padding-bottom: 0 !important;
    }
  }
}
