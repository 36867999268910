@import "../../../../../assets/styles/variables";

.singleItem {
  display: flex;
  align-items: center;
}

.singleItemAvatar {
  background: none;
  color: $text-color;
}

.singleItemContent {
  margin-left: 0.5rem;
  display: flex;
  flex-direction: column;
}
