@import "src/assets/styles/variables";

.list {
  &-pre {
    padding: 1rem;
    display: flex;
    justify-content: center;

    .ant-typography {
      margin-top: 1em;
      margin-bottom: 0;
    }

    .ant-input-search {
      width: 500px;
      max-width: 100%;
    }
  }

  .ant-list-empty-text {
    background-color: white;
  }

  .ant-list-item {
    padding-left: 1rem;
    padding-right: 1rem;
    padding-bottom: 1rem / 2;

    &:not(.section-title) {
      background-color: white;
      cursor: pointer;

      &:hover {
        opacity: 0.9;
      }
    }

    &.section-title {
      padding-top: 0;
      padding-bottom: 0;
      min-height: 2rem;
      background-color: $background-color;

      .ant-list-item-meta-title {
        font-weight: 600;
      }
    }

    &.active {
      border-top: 1px solid $primary-color;
      border-bottom: 1px solid $primary-color;
      padding: 11px 1rem 1rem / 2;

      .ant-list-item-meta-title {
        color: $primary-color;
      }
    }

    &.disabled {
      cursor: auto;

      .ant-list-item-meta-title {
        color: rgba(0, 0, 0, 0.25);
      }

      .ant-list-item-meta-avatar {
        opacity: 0.3;
      }

      &:hover {
        opacity: 1;
      }
    }

    .ant-list-item-meta {
      display: flex;
      align-items: center;

      &-title {
        margin-bottom: 0;
      }

      &-avatar {
        display: flex;
        align-items: center;

        .anticon {
          font-size: 24px;
        }

        img {
          height: 26px;
        }
      }

      &-description {
        font-size: 12px;
        display: flex;
        flex-flow: wrap;
        row-gap: 8px;
      }

      //& + div {
      //  :not(.ant-btn) {
      //    .anticon {
      //      padding: 1em;
      //    }
      //  }
      //}
    }

    & > .anticon-right {
      padding: 1em 0 1em 0.5em;
    }
  }

  .load-more-item {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.5em;

    button {
      margin: 0;
    }
  }
}
