@import "src/assets/styles/variables.scss";
@media screen {

  .contractPreview {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    background: $background-color;
  }

  .contractPreviewContainer {
    display: flex;
    gap: 2rem;
    flex-direction: column;
    align-items: flex-end;
  }

  .contractIframe {
    background: white;
    width: #{"min(210mm, 98vw)"};
    min-height: #{"min(260mm, 90vh)"};
    margin: auto;
    overflow: auto;
    border: none;
    box-shadow: 0 1px 3px -2px rgba(0, 0, 0, 0.12), 0 3px 8px 0 rgba(0, 0, 0, 0.08), 0 5px 14px 4px rgba(0, 0, 0, 0.05); // major shadow for layers
  }
}
