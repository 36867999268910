@import "src/assets/styles/variables";

.text {
  &-center {
    text-align: center;
  }

  &-left {
    text-align: left;
  }

  &-right {
    text-align: right;
  }
}

.d {
  &-flex {
    display: flex;
  }
}

.text {
  &-success {
    color: $success-color !important;
  }

  &-warning {
    color: $warning-color !important;
  }

  &-danger {
    color: $error-color !important;
  }

  &-primary {
    color: $primary-color !important;
  }

  &-secondary {
    color: $text-color-secondary !important;
  }

  &-white {
    color: #FFFFFF !important;
  }
}

.cursor-pointer {
  cursor: pointer;
}

.flex {
  &-direction {
    &-column {
      flex-direction: column;
    }
  }
}

.align-items {
  &-center {
    align-items: center;
  }

  &-baseline {
    align-items: baseline;
  }
}

.justify-content {
  &-center {
    justify-content: center;
  }

  &-space-between {
    justify-content: space-between;
  }

  &-flex-start {
    justify-content: flex-start;
  }

  &-flex-end {
    justify-content: flex-end;
  }
}

.m {
  &-0 {
    margin: 0 !important;
  }

  &-1 {
    margin: 0.5rem !important;
  }

  &-2 {
    margin: 1rem !important;
  }

  &-3 {
    margin: 2rem !important;
  }

  &t {
    &-0 {
      margin-top: 0 !important;
    }

    &-1 {
      margin-top: 0.5rem !important;
    }

    &-2 {
      margin-top: 1rem !important;
    }

    &-3 {
      margin-top: 2rem !important;
    }
  }

  &b {
    &-0 {
      margin-bottom: 0 !important;
    }

    &-1 {
      margin-bottom: 0.5rem !important;
    }

    &-2 {
      margin-bottom: 1rem !important;
    }

    &-3 {
      margin-bottom: 2rem !important;
    }
  }

  &l {
    &-0 {
      margin-left: 0 !important;
    }

    &-1 {
      margin-left: 0.5rem !important;
    }

    &-2 {
      margin-left: 1rem !important;
    }

    &-3 {
      margin-left: 2rem !important;
    }
  }

  &r {
    &-0 {
      margin-right: 0 !important;
    }

    &-1 {
      margin-right: 0.5rem !important;
    }

    &-2 {
      margin-right: 1rem !important;
    }

    &-3 {
      margin-right: 2rem !important;
    }
  }
}

.p {
  &-0 {
    padding: 0 !important;
  }

  &-1 {
    padding: 0.5rem !important;
  }

  &-2 {
    padding: 1rem !important;
  }

  &-3 {
    padding: 2rem !important;
  }

  &t {
    &-0 {
      padding-top: 0 !important;
    }

    &-1 {
      padding-top: 0.5rem !important;
    }

    &-2 {
      padding-top: 1rem !important;
    }

    &-3 {
      padding-top: 2rem !important;
    }
  }

  &b {
    &-0 {
      padding-bottom: 0 !important;
    }

    &-1 {
      padding-bottom: 0.5rem !important;
    }

    &-2 {
      padding-bottom: 1rem !important;
    }

    &-3 {
      padding-bottom: 2rem !important;
    }
  }

  &l {
    &-0 {
      padding-left: 0 !important;
    }

    &-1 {
      padding-left: 0.5rem !important;
    }

    &-2 {
      padding-left: 1rem !important;
    }

    &-3 {
      padding-left: 2rem !important;
    }
  }

  &r {
    &-0 {
      padding-right: 0 !important;
    }

    &-1 {
      padding-right: 0.5rem !important;
    }

    &-2 {
      padding-right: 1rem !important;
    }

    &-3 {
      padding-right: 2rem !important;
    }
  }

  &y {
    &-0 {
      padding-top: 0 !important;
      padding-bottom: 0 !important;
    }

    &-1 {
      padding-top: 0.5rem !important;
      padding-bottom: 0.5rem !important;
    }

    &-2 {
      padding-top: 1rem !important;
      padding-bottom: 1rem !important;
    }

    &-3 {
      padding-top: 2rem !important;
      padding-bottom: 2rem !important;
    }
  }

  &x {
    &-0 {
      padding-left: 0 !important;
      padding-right: 0 !important;
    }

    &-1 {
      padding-left: 0.5rem !important;
      padding-right: 0.5rem !important;
    }

    &-2 {
      padding-left: 1rem !important;
      padding-right: 1rem !important;
    }

    &-3 {
      padding-left: 2rem !important;
      padding-right: 2rem !important;
    }
  }
}

.w {
  &-100 {
    width: 100%;
  }
}

.h {
  &-100 {
    height: 100%;
  }
}

.font-weight {
  &-bold {
    font-weight: bold;
  }

  &-normal {
    font-weight: normal;
  }
}
