@import "../../../assets/styles/variables";

.layout {
  min-height: 100vh;
}

.layoutHeader {
  height: 48px;
  padding: 0 1rem;
}

.layoutHeaderTitle {
  margin-bottom: 0 !important;
  color: white !important;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
}

.content {
  min-height: 60vh;
  max-width: $max-platform-width - 500;
  background: $background-color;
  width: 100%;
  margin: auto;
}

.logoContainer {
  float: left;
  width: auto;
  height: 100%;
  margin: 0 2em 0 0;
  padding-left: 0 !important;
  color: white;
}

.logo {
  height: 1.5rem;
  width: auto;
  margin-right: 0.75rem;
}
