.sider {
}

.logo {
  width: 55px;
  height: 25px;
  object-fit: contain;
  margin: 8px auto !important;
  position: absolute;
  left: 0;
  right: 0;
}

.logoContainer {
  background: rgb(0, 33, 64);
  margin-top: 0 !important;
}

@media (max-width: 767px) {
  .sider {
    display: none;
  }
}
